import { Splide } from "@splidejs/splide";

// Shop The Look Main Carousel
class ShopTheLookCarousel extends HTMLElement {
  constructor() {
    super();
    this.splide = null;
    this.selectedBlockId = null;
  }

  connectedCallback() {
    this.initializeSplide();
    this.themeEditorListeners();
  }

  initializeSplide() {
    this.splide = new Splide(this, {
      type       : 'slide',
      pagination : false,
      arrows     : true,
      perPage    : 1,
      perMove    : 1,
      gap        : '1rem',
      arrowPath  : 'm2.2742.9395 20 19.9999-20 20.0001-2.1149-2.115 17.8851-17.8851-17.8851-17.885 2.1149-2.1149z',
      breakpoints : {
        1024: {
          pagination : true,
          arrows     : false,
        }
      },
    });
    this.splide.mount();
  }

  themeEditorListeners() {
    document.addEventListener('shopify:block:select', (event) => {
      if (event.target.closest('shop-the-look-carousel') === this) {
        this.selectedBlockId = event.detail.blockId;
        const index = this.getSlideIndexByBlockId(this.selectedBlockId);
        if (index !== -1) {
          this.splide.go(index);
        }
      }
    });

    document.addEventListener('shopify:block:deselect', (event) => {
      if (event.target.closest('shop-the-look-carousel') === this) {
        this.selectedBlockId = null;
      }
    });

    document.addEventListener('shopify:section:load', (event) => {
      if (event.target.closest('shop-the-look-carousel') === this) {
        this.initializeSplide();
        if (this.selectedBlockId) {
          const index = this.getSlideIndexByBlockId(this.selectedBlockId);
          if (index !== -1) {
            this.splide.go(index);
          }
        }
      }
    });
  }

  getSlideIndexByBlockId(blockId) {
    return Array.from(this.splide.Components.Elements.slides).findIndex(
      slide => slide.dataset.blockId === blockId
    );
  }
}

customElements.define('shop-the-look-carousel', ShopTheLookCarousel);

// Shop The Look Product Carousel
class ShopTheLookProductCarousel extends HTMLElement {
  constructor() {
    super();
    this.splide = null;
    this.hotspots = null;
    this.current = null;
  }

  connectedCallback() {
    this.splide = new Splide(this, {
      type       : 'fade',
      pagination : true,
      arrows     : false,
      perPage    : 1,
      perMove    : 1,
      gap        : '1rem',
      width      : '100%',
      focus      : 'center',
      breakpoints : {
        1024: {
          pagination : false,
        }
      },
    });

    // Find the carousel-navigation element for the block
    const carouselNavId = this.getAttribute('id');
    const carouselNav = document.querySelector(`carousel-navigation[aria-controls="${carouselNavId}"]`);

    if (carouselNav) {
      this.hotspots = carouselNav.querySelectorAll('button');

      this.hotspots.forEach((hotspot, index) => {
        this.initHotspot(hotspot, index);
      });

      this.splide.on('mounted move', () => {
        this.updateHotspots();
      });
    }

    this.splide.mount();
  }

  initHotspot(hotspot, index) {
    hotspot.addEventListener('click', () => {
      this.splide.go(index);
    });
  }

  updateHotspots() {
    const activeHotspot = this.hotspots[this.splide.index];

    if (activeHotspot) {
      if (this.current) {
        this.current.setAttribute('aria-current', 'false');
      }
      activeHotspot.setAttribute('aria-current', 'true');
      this.current = activeHotspot;
    }
  }
}

customElements.define('shop-the-look-product-carousel', ShopTheLookProductCarousel);
